


























































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  @Prop() label!: string
  private amount = ''
  private reason = ''
  private onLoad = false
  private regNumber = /\d/
  private datePicker = false
  private rule = {
    require: (value: string) => value.length > 0 || 'Required',
    isNumber: (value: string) =>
      this.regNumber.test(value) || 'Must be a number',
  }
  private date = new Date().toISOString().slice(0, 10)
  private formatedDate = this.dateFormat(this.date)

  @Watch('date')
  onDateChange(val: string) {
    this.formatedDate = this.dateFormat(val)
  }

  private dateFormat(dateIn: string) {
    const [year, month, date] = dateIn.split('-')
    return `${date}/${month}/${year}`
  }
  private closed() {
    this.isShow = false
  }
  private async submit() {
    this.onLoad = true
    if (this.label === 'FHB') {
      const displayDate = new Date()
      const [year, month, date] = this.date.split('-')
      displayDate.setDate(Number(date))
      displayDate.setMonth(Number(month) - 1)
      displayDate.setFullYear(Number(year))
      await vxm.userTransaction.createCustomFhbTransaction({
        amount: Number(this.amount),
        reason: this.reason,
        displayDate: displayDate,
      })
    } else if (this.label === 'Refund') {
      await vxm.userTransaction.createRefundTransaction({
        amount: Number(this.amount),
        reason: this.reason,
      })
    } else {
      await vxm.userTransaction.createFineTransaction({
        amount: Number(this.amount),
        reason: this.reason,
      })
    }
    this.amount = ''
    this.reason = ''
    this.onLoad = false
    this.closed()
  }
}
