



























































































































































































import { Component, Vue } from 'vue-property-decorator'
import Absent from '@/components/Admin/Absent.vue'
import Refund from '@/components/Admin/Refund.vue'
import TopUp from '@/components/Admin/TopUp.vue'
import { vxm } from '@/store'
import { Wallet, TransactionName } from '../types/transaction'

@Component({
  components: {
    Absent,
    Refund,
    TopUp,
  },
  computed: {
    onFetch: {
      get: () => {
        return vxm.userTransaction.fetchStatus
      },
    },
    subject: {
      get: () => {
        return vxm.userTransaction.getSubject
      },
    },
    userInfo: {
      get: () => {
        return vxm.userTransaction.userInfo
      },
    },
    wallets: {
      get: () => {
        return vxm.userTransaction.userWallet
      },
    },
    students: {
      get: () => {
        return vxm.allUsers.studentsForAutoComplete
      },
    },
  },
})
export default class extends Vue {
  private folderId = ''
  private showError = false
  private errorMessage = ''
  private wallets!: Wallet[]
  private showData = false
  private label = ''
  private showTopup = false
  private showRefund = false
  private showAbsent = false

  private get currentWallet() {
    if (this.wallets.length < 2) {
      return this.wallets
    } else {
      return this.wallets.slice(0, -1)
    }
  }

  private get lastTopup() {
    if (this.wallets.length > 1) {
      return this.wallets.slice(-1)
    } else {
      return []
    }
  }

  mounted() {
    vxm.allUsers.fetchUsers()
  }

  private topup() {
    this.showTopup = true
  }
  private fhb() {
    this.label = 'FHB'
    this.showRefund = true
  }
  private refund() {
    this.label = 'Refund'
    this.showRefund = true
  }
  private fine() {
    this.label = 'Fine'
    this.showRefund = true
  }
  private absent() {
    this.label = 'Absent'
    this.showAbsent = true
  }
  private drop() {
    this.label = 'Drop'
    this.showAbsent = true
  }
  private getClass(idx: number): string {
    const walletName = this.wallets[idx].name
    if (walletName) {
      return `display-4 ${
        idx === 0 ? 'font-weight-bold' : 'font-weight-thin'
      } ${this.getTextColorClass(walletName)}`
    } else {
      return ''
    }
  }
  private getTextColorClass(name: TransactionName) {
    switch (name) {
      case TransactionName.bt:
      case TransactionName.bc:
        return 'blue--text text--darken-2'
      case TransactionName.yc:
        return 'yellow--text text--darken-2'
      case TransactionName.cv:
        return 'red--text text--darken-2'
    }
  }
  private async onSubmit() {
    const regEx = /\d{6}/
    if (this.folderId.length === 6 && regEx.test(this.folderId)) {
      await vxm.userTransaction.fetchUserTransacction(this.folderId)
      this.folderId = ''
      this.showData = true
    } else {
      this.errorMessage = 'Wrong input'
      this.showError = true
      setTimeout(() => {
        this.folderId = ''
        this.showError = false
        this.errorMessage = ''
      }, 1000)
    }
  }
}
