




































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  @Prop() label!: string
  private amount = ''
  private onLoad = false

  @Watch('isShow')
  onShowChange(val: boolean) {
    if (val) {
      if (this.label === 'Absent') {
        this.amount = '1'
      } else {
        this.amount = vxm.userTransaction.userTotalCoin / 800 + ''
      }
    }
  }

  private closed() {
    this.isShow = false
  }
  private async submit() {
    this.onLoad = true
    if (this.label === 'Absent') {
      await vxm.userTransaction.createAbsentTransaction(Number(this.amount))
    } else {
      await vxm.userTransaction.createDropTransaction(Number(this.amount))
    }
    this.isShow = false
    this.onLoad = false
  }
}
